import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: 'https://9c6688047bcd4bc0aaeceb3a28c2d81e@o10564.ingest.sentry.io/50621',
  // To set your release version
  release: 'site-frontend-production@<DOTCOM_VERSION>',
  integrations: [
    new Integrations.BrowserTracing(),
    new ExtraErrorDataIntegration({
      depth: 3,
    }),
    new Sentry.Integrations.Breadcrumbs({ console: false }),
  ],
  ignoreErrors: ['Non-Error promise rejection captured'],
  environment: 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  allowUrls: ['steelseries.com'],
});
