import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '7ab15b65-949a-4ec9-a0eb-bd71aa383cf9',
  clientToken: 'pub0ebbf741dc7e33f3a4ca1dfa0f098ba3',
  site: 'datadoghq.com',
  service: 'dotcom',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  //  service: 'my-web-application',
  env: '<ENVIRONMENT>',
  sampleRate: 2, //Percentage of sampleRate 100 max, 0 is none
  trackInteractions: true,
});
